import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationForm, UserRegistration } from 'src/app/core/store/sign-up/models/sign-up.model';

interface UserLogin {
  login: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  public loginData: UserLogin = {
    login: '',
    password: '',
  };
  constructor(private http: HttpClient) {}

  private registrationFormData: RegistrationForm | null = null;

  public checkRegisterParams(params: RegistrationForm) {
    this.registrationFormData = params;
    return this.http.post('/validate/user-register', new UserRegistration(this.registrationFormData, true));
  }
  // public checkRegisterParamsCoduluz(params: RegistrationForm) {
  //   this.registrationFormData = params;
  //   return this.http.post('/casino-api/auth/isRegistered', { email: params.email });
  // }
  getUserProfile() {
    return this.http.get('/profiles');
  }
  generateRandomPostalCode() {
    const length: number = Math.floor(Math.random() * 2) + 1;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result[length - 1];
  }

  generateRandomString() {
    const length = Math.floor(Math.random() * 6) + 2;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result;
  }
  register(params: RegistrationForm) {
    this.loginData.login = params.email;
    this.loginData.password = params.password;

    return this.http.post(
      '/profiles', // '/casino-api/auth/signup',

      {
        ...params,
        ...{ login: String(params.phoneCode).replace('+', '') + String(params.phoneNumber) },
        ...{ passwordRepeat: params.password },
      },
      { headers: new HttpHeaders().set('X-UA-Fingerprint', params?.fingerprint) }
    );
  }
}
